import { ConfigurationProfile } from "@equipments/configuration-profiles/models";
import { Exportable } from "@models/exporter.model";
import { Device } from "./device.model";

export class ValidateDeviceDTO implements Exportable {
  device:               Device = new Device();
  configurationProfile: ConfigurationProfile = new ConfigurationProfile();
  equipmentCode:        string | undefined;
  confirmationKey:      string | undefined;
}

