import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";
import { EQUIPMENT_GROUP_TYPE } from "../enums/equipment-group.enum";
import { Customer } from "@customers/customers-mgmt/models";

export class EquipmentGroup implements Exportable {


  id:         number = 0;
  version:    number | undefined;
  name:       string | undefined;
  equipmentGroupTypeId: EQUIPMENT_GROUP_TYPE | undefined;

  customerId: number | undefined;

  customer:   Customer = new Customer();


  constructor(){}

}

export class EquipmentGroupFilter implements QueryFilter {

  equipmentGroupTypeId: EQUIPMENT_GROUP_TYPE = EQUIPMENT_GROUP_TYPE.CODIGOS_POSTALES;
  name:      string | undefined;

}


export class EquipmentGroupExporter implements Exporter  {

  name;


  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
  }
}




