import { ConfigurationProfile } from "@equipments/configuration-profiles/models";
import { Exportable } from "@models/exporter.model";
import { EquipmentGroup } from "@configuration/equipment-groups/models";

export class ApplyConfigurationEquipmentGroupDTO implements Exportable {
  equipmentGroup:       EquipmentGroup = new EquipmentGroup();
  configurationProfile: ConfigurationProfile = new ConfigurationProfile();
  confirmationKey:      string | undefined;
}

