
export enum EQUIPMENT_GROUP_TYPE {

  CODIGOS_POSTALES = 1,
  HORARIOS = 2,
  RUTAS = 3,
  PERSONALIZADOS = 4,
  ZONAS = 5

}


export function getEquipmentModeTypes(): { value: number, name: string }[] {

  return Object.keys(EQUIPMENT_GROUP_TYPE)
  .filter((key: any) => !isNaN(Number(EQUIPMENT_GROUP_TYPE[key])))
  .map((key: any) => {
    return { value: Number(EQUIPMENT_GROUP_TYPE[key]), name: key };
  });
}
