import { ConfigurationProfile } from "@equipments/configuration-profiles/models";
import { Exportable } from "@models/exporter.model";
import { Equipment } from "@equipments/equipments-mgmt/models";

export class ApplyConfigurationEquipmentsDTO implements Exportable {
  equipments:           Equipment[] = [];
  configurationProfile: ConfigurationProfile = new ConfigurationProfile();
  confirmationKey:      string | undefined;
}

